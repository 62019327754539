<template>
  <v-container style="max-width: 1240px">
    <v-row class="mt-10">
      <v-col cols="12" sm="4">
        <v-img :src="media" :aspect-ratio="1"></v-img>
      </v-col>
      <v-col cols="12" sm="7" class="main-card px-md-8 ">
        <h1 class="mb-8">{{ body.title }}</h1>
        <div v-html="body.content" class="text-break"></div>
      </v-col>
    </v-row>

    <v-row class="mt-7">
      <v-col cols="12" sm="6" md="4" v-for="(newsItem, idx) in moreItems" :key="idx">
        <v-card flat color="#F6F6F3" rounded="0" class="fill-height d-flex flex-column">
          <router-link :to="{name: 'NieuwsItem', params: {id: newsItem.id}}">
            <v-img :src="newsItem.featured_image"  :aspect-ratio="16/9"></v-img>
          </router-link>

          <v-card-title class="font-weight-bold text-break" style="line-height: 1.1;color:#595853;">{{ newsItem.title }}</v-card-title>
          <v-card-text class="flex-grow-1 mt-auto justify-end">
            <div class="fill-height d-flex flex-column flex-grow-1 justify-end align-content-end">
              <p v-html="newsItem.excerpt"></p>
              <p>
                <router-link :to="{name: 'NieuwsItem', params: {id: newsItem.id}}">Lees verder >></router-link>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import http from "@/http";
export default {
  name: "nieuwsitem",
  data() {
    return {
      body: {},
      media: [],
      loading: false,
      moreItems: []
    }
  },
  async mounted() {
    const resp = await http.get(`/p/${this.$route.params.id}`);
    this.media = resp.data.data.featured_image;
    this.body = resp.data.data.content.find(x => x.code === 'nl');
    this.body.content = this.body.content.replace("<a ", "<a target=\"_blank\"");
    let resp2 = await http.get(`/p/nl/nieuws?count=4`);
    this.moreItems = resp2.data.data.filter(x => x.id != this.$route.params.id).slice(0, 3);
    this.loading = false;
  }
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
