<template>
  <v-container style="max-width: 1240px">
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="(newsItem, idx) in newsItems" :key="idx">
        <v-card flat color="#F6F6F3" rounded="0" class="fill-height d-flex flex-column">
          <router-link :to="{name: 'NieuwsItem', params: {id: newsItem.id}}">
            <v-img :src="newsItem.featured_image"  :aspect-ratio="16/9"></v-img>
          </router-link>

          <v-card-title class="font-weight-bold text-break" style="line-height: 1.1;color:#595853;">{{ newsItem.title }}</v-card-title>
          <v-card-text class="flex-grow-1 mt-auto justify-end">
            <div class="fill-height d-flex flex-column flex-grow-1 justify-end align-content-end">
            <p v-html="newsItem.excerpt"></p>
            <p>
              <router-link :to="{name: 'NieuwsItem', params: {id: newsItem.id}}">Lees verder >></router-link>
            </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="hasMore" style="position:relative;">
    <v-overlay :value="loadMoreLoading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-col >
      <v-card flat class="d-flex align-center justify-center flex-column clickable"  @click="loadMore">
        <span class="text-uppercase" style="font-size: 1.2rem;">{{ $t('meer_berichten') }}</span>
        <v-icon>mdi-chevron-down</v-icon>
      </v-card>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import http from "@/http";

export default {
  name: "Nieuws.vue",
  data() {
    return {
      newsItems: [],
      meta: {},
      loadMoreLoading: false
    }
  },
  async mounted() {
    let resp = await http.get(`/p/nl/nieuws`);
    this.newsItems = resp.data.data;
    this.meta = resp.data.meta;
  },
  methods: {
    async loadMore() {
      if (!this.hasMore)
        return;
      this.loadMoreLoading = true;
      let resp = await http.get(`/p/${this.$route.params.locale}/${this.$route.params.type}?count=9&page=` + (this.meta.current_page+1));
      this.items = [...this.items, ...resp.data.data];

      this.meta = resp.data.meta;
      this.loadMoreLoading = false;
      this.$nextTick(() => {
        document.querySelector(".footer .row:last-of-type").scrollIntoView({behavior: 'smooth'});
      });
    },
  },
  computed: {
    hasMore() {
      if (!this.meta) return false;
      return this.meta.current_page !== this.meta.last_page;
    }
  },
}
</script>

<style scoped>

</style>
