<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Frame Vastgoed</h2>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/Frame kantoor.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>
          Frame Vastgoed heeft in 2016, 2017 en 2018, als eigenaar van sociale huurwoningen een procedure tegen de verhuurderheffing gevoerd. 
          De achtergrond was een sterk gevoel van onrechtvaardigheid over de verhuurderheffing bij de eigenaar van Frame Vastgoed, Frank Blom. 
          Voornamelijk omdat de heffing door de sociale huurders moest worden opgebracht, ten gunste van de algemene middelen, speelde daarin 
          een rol en dat het een heffing is voor winst en belastingen waardoor de heffing een huur opdrijvende werking had.
        </p>
        <p>
          In het kader van deze procedure heeft advocaat mr. Z.H. (Zeno) van Dorth tot Medler, in opdracht van Frame diepgaand onderzoek verricht 
          naar de juridische achtergrond en ontstaansgeschiedenis van de verhuurderheffing en gronden gevonden om bezwaar te maken hiertegen. 
          Vervolgens heeft Frame tegen de verhuurderheffing geprocedeerd.
        </p>
        <p>
          De Belastingdienst heeft er in 2018 voor gekozen om af te zien van inhoudelijke behandeling door de rechter en in dat kader alle door 
          Frame betaalde verhuurderheffing aan Frame teruggestort, waarmee de procedure tegen de verhuurderheffing werd beëindigd
        </p>
        <p>
          Frame heeft daarna een manier gezocht de gevonden gronden beschikbaar te stellen aan verhuurders van sociale woningen. 
          Dit heeft geleid tot een collectieve procedure, waaraan elke verhuurder van sociale huurwoningen, 
          corporaties en marktpartijen konden deelnemen: Dit is de Class Action Verhuurderheffing geworden.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Corporaties"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
