<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">De verhuurderheffing verhindert de benodigde investeringen</h2>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-regular" style="line-height: 1.5;font-size: 20px">In het jaar 2013 is de verhuurderheffing in het leven geroepen: een 
          ingrijpende belastingmaatregel die alleen aan verhuurders van sociale huurwoningen werd opgelegd. 
          De verhuurderheffing was ooit bedoeld als crisismaatregel, om een gat in de Rijksbegroting te vullen.</h3>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/CA-1.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>Verhuurders van sociale huurwoningen hebben sinds 2013 verhuurderheffing aan de Belastingdienst betaald. 
          De omvang van de betaalde verhuurderheffing bedraagt meer dan 13 miljard euro: geld dat ten gunste is gekomen 
          van de algemene middelen en onttrokken werd aan de sociale huursector.</p>
        <p>Vanaf 1 januari 2023 is de verhuurderheffing afgeschaft. Het kabinet maakte in ruil daarvoor landelijke 
          prestatieafspraken met woningcorporaties over wat zij de komende jaren doen op het gebied van woningbouw, 
          betaalbare huren, verduurzaming, woningverbetering en leefbaarheid.</p>
        <p><strong>Class Action</strong></p>
        <p>Vanaf 2019 is er een substantiële groep van deelnemers binnen de Class Action geformeerd bestaande uit corporaties 
          en private verhuurders van sociale huurwoningen. De Class Action Verhuurderheffing is een collectieve procedure tegen 
          de verhuurderheffing. De groep is gegroeid tot 57 deelnemingen. De totale omvang van verhuurderheffing (over de jaren 2019, 2020, 2021 en 2022) 
          die met de Class Action gemoeid is bedraagt rond de 155 miljoen euro. De class action is voor de deelnemers op basis van ‘no-cure-no-pay’. De deelnemers betalen een succesfee, afhankelijk van de omvang van de verhuurdersheffing, die bij een positieve uitspraak wordt betaald.
          Van dit bedrag zullen alle kosten voor de procedure, de proceskosten en de omzetbelasting afgedragen worden. De middelen die resteren worden door Jeremia geïnvesteerd 
          in de ontwikkeling, de realisatie en de verduurzaming van sociale huurwoningen en maatschappelijk vastgoed.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
