<template>
  <v-container style="max-width: 1240px">
    <h2 class="mb-6 mt-6">Veelgestelde vragen Class Action verhuurderheffing</h2>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel
              v-for="(item,i) in faq"
              :key="i"
              class="mb-3"
          >
            <v-expansion-panel-header color="#F2F2F2">
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.text }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import faq from '@/data/faq';
export default {
  name: "Faq",
  data() {
    return {
      faq
    }
  }
}
</script>

<style scoped>

</style>
