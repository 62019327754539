<template>
  <v-container style="max-width: 1240px">
    <h2 class="mb-6 mt-6">Contact met Class Action Verhuurderheffing</h2>
  <v-row>
    <v-col>
      <h4>Class Action Verhuurderheffing</h4>
      <span>Voorstraat 31</span><br>
      <span>3231 BE Brielle</span><br>
      <span>Telefoon: 0181 - 45 95 15</span><br>
      <span>E-mail: <a href=mailto:info@classactionverhuurderheffing.nl>info@classactionverhuurderheffing.nl</a></span><br><br>
      <span><a href="https://classactionverhuurderheffing.nl">classactionverhuurderheffing.nl</a></span><br>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>
