<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Aanmelden als nieuwe deelnemer is niet meer mogelijk</h2>
    <v-row>
      <v-col cols="12">
        <p>
          Woningcorporaties betalen veruit het grootste deel van de verhuurderheffing. 
          De Class Action Verhuurderheffing probeert de heffing aan te vechten, op nog niet eerder gebruikte unieke gronden. 
          Om voor woningcorporaties deelname aan de Class Action te vereenvoudigen, is er medio september 2020 overleg gevoerd met een tiental 
          woningcorporaties over de vraag: “Wat moet er anders, om deelname voor corporaties mogelijk te maken?” In overleg met een informele 
          vertegenwoordiging van vier corporaties uit deze groep (de Kerngroep 1) zijn aanpassingen in de opzet van de collectieve procedure doorgevoerd, 
          zodat die beter aansluit op woningcorporaties. Ook verdere coördinatie van de procedure geschiedt in overleg met deze Kerngroep.
        </p>        
        <h4>Model-deelnameovereenkomst</h4>
        <p>
          De Class Action Verhuurderheffing heeft samen met de Kerngroep de deelnameovereenkomst opgesteld, aansluitend bij
          de behoeften van woningcorporaties. Deze bestaat uit twee fasen:
        </p>
        <ul>
          <li>de hiervoor genoemde voorlopige deelnameovereenkomst waarmee u kennis krijgt van de unieke
            gronden van de procedure; deze gaat over in een</li>
          <li>definitieve deelnameovereenkomst als u daarna besluit deel te nemen aan de procedure.</li>
        </ul>
        <p>
          Transparantie en inspraak voor woningcorporaties gedurende de gehele procedure is het uitgangspunt, de Kerngroep
          speelt daarin een grote rol.
        </p>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Deelnemen"
}
</script>

<style scoped>

</style>
