export default [
    {
        title: 'Hoeveel deelnemers heeft de Class Action nu?',
        text: 'De Class Action Verhuurderheffing bestaat uit 56 deelnemers'
    },
    {
        title: 'Wat voor deelnemers doen er mee?',
        text: 'Het betreft beleggers en woningbouw corporaties, in iedergeval organisaties met meer dan 50 sociale huurwoningen.'
    },
    {
        title: 'Wie zijn deze deelnemers?',
        text: 'Er is afgesproken dat er niet gecommuniceerd wordt over welke bedrijven en corporaties het betreft. Het is een diverse groep van deelnemers vanuit de sector. Groot en klein, corporaties, stichtingen en marktpartijen die beleggen in sociale huurwoningen.'
    },
    {
        title: 'Waarom is het infitiatief voor de Class Action genomen',
        text: 'De achtergrond was een sterk gevoel van onrechtvaardigheid over de verhuurderheffing bij de initiatiefnemer. Voornamelijk omdat de heffing door de sociale huurders werd opgebracht speelde daarin een rol en dat het een heffing is voor winst en belastingen waardoor de heffing een huur opdrijvende werking heeft. De verhuurderheffing verhindert sinds de invoering daarvan de broodnodige investeringen in de sector waardoor het verduurzamen en bijbouwen van betaalbare woningen wordt vertraagd en het woningaanbod voor de mensen met lagere inkomens steeds nijpender wordt. Op de website; classactionverhuurderheffing.nl is beschreven waarom de class action is opgezet, de omvang daarvan en het verloop van de procedure.'
    },
    {
        title: 'Waarom is er gekozen voor een Class Action, no-cure-no-pay procedure?',
        text: 'Om een zo laag mogelijke drempel voor deelname aan de Class Action. Doordat er geen kostenrisico is voor de deelnemers onstond er een substantiele groep en kon er materieel actie ondernomen worden.'
    },
    {
        title: 'Waarom durft de initiatiefnemer het risico van een no-cure-no-pay procedure aan?',
        text: 'Aan het opzetten van de Class Action Verhuurderheffing ligt een intensief onderzoek ten grondslag verricht door gespecialiseerde advocaten. Uit dit onderzoek is gebleken dat de wet Verhuurderheffing op bepaalde wezenlijke punten ‘juridisch onhoudbaar’ is. Deze argumenten zijn in eerdere procedures over de onrechtmatigheid van de Verhuurderheffing, nog nooit aangevoerd.'
    },
    {
        title: 'Wat gebeurt er bij een succesvolle procedure met de ontvangen succesfee?',
        text: 'Jeremia heeft, in de statuten opgenomen, ten doel een bijdrage te leveren aan het te kort aan betaalbare (socialehuur-) woningen, waaronder het verminderen van een tekort, het bevorderen van verduurzaming van huurwoningen en investeren in maatschappelijk vastgoed. Bij een evenutele winst van de procedure en terugbetaling van de betaalde verhuurderheffing (over de bezwaartermijnen) zullen de deelnemers deze middelen weer aanwenden om te investeren. Daarnaast dragen zij een % ‘succesfee’ af aan Jeremia. Jeremia zal de middelen verkregen uit de Class Action aanwenden voor genoemde doelstellingen.'
    },
    
    {
        title: 'Op welke grondslag menen jullie dat de verhuurderheffing onrechtvaardig is?',
        text: 'De procedure wordt op basis van meerdere gronden gevoerd. Deze zijn puur ‘wet-technisch’ en hebben niets te maken met de eigenschappen van de deelnemende sociale verhuurder. Daardoor kan ook iedere sociale verhuurder die verhuurderheffing moet betalen, meedoen. Onder andere menen wij dat op het terrein van de IAH, deze als verboden staatsteun moet worden beschouwd, dan wel als strijdig met het gelijkheidsbeginsel en om die reden moet de verhuurderheffing (ook over afgelopen jaren) buiten werking worden gesteld. Dit t.a.v. een samenhang tussen de verhuurderheffing en IAH.'
    }
];
