<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Jeremia</h2>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/Missie.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>
          Voor de Class Action Verhuurderheffing is de onderneming Jeremia B.V. opgericht. 
          Jeremia heeft ten doel een bijdrage te leveren aan het tekort van sociale huurwoningen, 
          het verduurzamen daarvan en het investeren in maatschappelijk vastgoed. 
          Jeremia heeft een tweehoofdige directie die bestaat uit; P. Rutten en P. van Bekhoven, 
          beiden ook werkzaam bij Frame Vastgoed.
        </p>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Jeremia"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
